import { useAppDisplayModeContext } from '@archipro-design/aria';
import { useTracker } from '@archipro-website/tracker';
import { useFetcher } from '@remix-run/react';
import { useLatest } from 'ahooks';
import { useEffect, useRef } from 'react';
import type { action } from '~/routes/remix-api.home.toggle-home-pin';
import { TOGGLE_HOME_PIN } from '~/routes/remix-api.home.toggle-home-pin';
import { fetcherIsDone } from '~/utils/fetcherHelper';
import type { Me } from 'generated/graphql';
import { useUser } from '~/modules/user';
type CallbackFn = (error: { __typename: string; Message: string }) => void;

export const useToggleHomePin = (canHomePin: boolean, onError?: CallbackFn) => {
    const { desktop: isDesktop } = useAppDisplayModeContext();
    const fetcher = useFetcher<typeof action>();
    const onErrorRef = useLatest<CallbackFn | undefined>(onError);
    const tracker = useTracker();
    const user = useUser() as Me;
    const eventData = useRef({
        ItemID: 0,
        ExtraData: {
            pinned: false,
            userId: user.ID,
            userName: `${user.FirstName} ${user.LastName}`,
        },
    });
    const toggleHomePin = async (itemID: number, pin: boolean) => {
        if (!isDesktop || !canHomePin || itemID <= 0) {
            return;
        }
        eventData.current.ItemID = itemID;
        eventData.current.ExtraData.pinned = pin;
        fetcher.submit(
            {
                itemID: itemID,
                pin: pin,
            },
            {
                method: 'post',
                action: TOGGLE_HOME_PIN,
            }
        );
    };

    useEffect(() => {
        const hasError =
            fetcherIsDone(fetcher) &&
            fetcher.data?.HomePinToggle?.__typename === 'UserError';
        if (hasError) {
            onErrorRef.current?.(fetcher.data.HomePinToggle);
        }
    }, [fetcher, onErrorRef, tracker]);

    useEffect(() => {
        if (
            fetcherIsDone(fetcher) &&
            fetcher.data?.HomePinToggle?.__typename !== 'UserError'
        ) {
            tracker.log('HomePinToggle', {
                url: new URL(window.location.href),
                data: eventData.current,
            });
        }
    }, [fetcher, tracker]);
    return {
        toggleHomePin,
    };
};
